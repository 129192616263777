import { captureRemixErrorBoundaryError, withSentry } from "@sentry/remix";
import {
  Box,
  Button,
  ThemeProvider,
  Typography,
  unstable_useEnhancedEffect as useEnhancedEffect,
} from "@mui/material";
import type { LinksFunction, LoaderFunctionArgs } from "@remix-run/cloudflare";
import { json } from "@remix-run/cloudflare";
import {
  Link,
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  isRouteErrorResponse,
  useLoaderData,
  useRouteError,
} from "@remix-run/react";
import type { MainSchema } from "./schemas";
import type { ProductJson } from "./jsonSchemas";
import { register } from "swiper/element/bundle";
import { withEmotionCache } from "@emotion/react";
import { useContext } from "react";
import ClientStyleContext from "./ClientStyleContext";
import getTheme from "./theme";
import { getSession } from "./sessions.server";
import { ClientOnly } from "remix-utils/client-only";
import ScrollToTop from "./components/ScrollToTop";
import styles from "./root.module.css";

// register Swiper custom elements
register();

export async function loader({ context, request }: LoaderFunctionArgs) {
  const env = context.cloudflare.env;
  const [main, products, uId] = await Promise.all([
    fetch(env.DTW_MAIN_URL).then((r) => r.json()),
    fetch(env.DTW_PRODUCTS_URL).then((r) => r.json()),
    getSession(request.headers.get("Cookie")).then((s) => s.get("userId")),
  ]);

  const obj = {
    main: main as MainSchema,
    products: products as ProductJson[],
    userId: uId,
  };
  return json(obj);
}

export const links: LinksFunction = () => {
  return [
    {
      rel: "preconnect",
      href: "https://fonts.googleapis.com",
    },
    {
      rel: "preconnect",
      href: "https://fonts.gstatic.com",
    },
    {
      rel: "stylesheet",
      href: "https://fonts.googleapis.com/icon?family=Material+Icons",
    },
    {
      rel: "stylesheet",
      href: "https://fonts.googleapis.com/css2?family=Lexend+Deca:wght@100..900&display=swap",
    },
    // {
    //   rel: 'icon',
    //   href: '/logo.png',
    //   type: 'image/png'
    // }
  ];
};

export const Layout = withEmotionCache(
  ({ children }: { children: React.ReactNode }, emotionCache) => {
    const data = useLoaderData<typeof loader>();
    const theme = getTheme(data?.main);

    const clientStyleData = useContext(ClientStyleContext);

    // Only executed on client
    useEnhancedEffect(() => {
      // re-link sheet container
      emotionCache.sheet.container = document.head;
      // re-inject tags
      const tags = emotionCache.sheet.tags;
      emotionCache.sheet.flush();
      tags.forEach((tag) => {
        // eslint-disable-next-line no-underscore-dangle, @typescript-eslint/no-explicit-any
        (emotionCache.sheet as any)._insertTag(tag);
      });
      // reset cache to reapply global styles
      clientStyleData.reset();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
      <html lang="en" suppressHydrationWarning>
        <head>
          <meta httpEquiv="Content-Type" content="text/html;charset=utf-8" />
          <meta charSet="utf-8" />
          <meta name="viewport" content="width=device-width, initial-scale=1" />
          <Meta />
          <meta
            name="emotion-insertion-point"
            content="emotion-insertion-point"
          />
          <Links />
        </head>
        <body style={styles}>
          <ThemeProvider theme={theme}>
            {children}
            <ClientOnly>{() => <ScrollToTop />}</ClientOnly>
          </ThemeProvider>
          <ScrollRestoration />
          <Scripts />
        </body>
      </html>
    );
  }
);

export function ErrorBoundary() {
  const error = useRouteError();
  let errorTitle = "We're sorry, an unexpected error has occured.";
  let errorSubtitle =
    "This problem has been automatically reported. See the latest offers in our store.";
  if (isRouteErrorResponse(error)) {
    if (error.status === 404) {
      errorTitle = "We're sorry, the page you are looking for cannot be found.";
      errorSubtitle = "See the latest offers in our store.";
    }
  }

  captureRemixErrorBoundaryError(error);

  return (
    <Box
      sx={{
        m: 3,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        height: "100dvh",
      }}
    >
      <Typography variant="h4">{errorTitle}</Typography>
      <Typography variant="h4" sx={{ marginBottom: (t) => t.spacing(2) }}>
        {errorSubtitle}
      </Typography>
      <Link to="/">
        <Button variant="contained">Go to store</Button>
      </Link>
    </Box>
  );
}

function App() {
  return <Outlet />;
}
export default withSentry(App);
